import React from "react"
import {
  makeStyles,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Theme,
  createStyles,
} from "@material-ui/core"

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    padding: "10px 0",
    margin: 0,
    "& h2, h3": {
      color: theme.palette.primary.main,
      margin: 0,
      textAlign: "center",
      fontWeight: theme.typography.fontWeightBold,
    },
    "& p": {
      margin: "10px 0 40px",
      padding: 0,
    },
    "& a": {
      textDecoration: "none",
      color: theme.palette.secondary.main,
      transition: "color 0.15s ease-in-out",
      "&:hover": {
        color: theme.palette.primary.dark,
      },
    },
    "& span": {
      fontWeight: theme.typography.fontWeightBold,
    },
  },
  table: {
    marginBottom: "40px",
    border: `3px solid ${theme.palette.grey[300]}`,
    borderRadius: "8px",
    "& .MuiTableCell-root": {
      paddingLeft: "2px",
      paddingRight: "2px",
      textAlign: "center",
      "&:nth-last-child(1)": {},
    },
  },
  headTh: {
    backgroundColor: theme.palette.grey[300],
    fontWeight: theme.typography.fontWeightBold,
  },
}))

export const cookiesTableData = {
  headers: [
    { name: "Dostawca cookies", minWidth: "74px" },
    { name: "Nazwa Cookies", minWidth: "60px" },
    { name: "Ważność", minWidth: "70px" },
    { name: "Typ", minWidth: "70px" },
    { name: "Własne / 3rd party", minWidth: "70px" },
    { name: "Zastosowanie", minWidth: "156px" },
  ],
  rows: [
    [
      "Google",
      "_ga",
      "2 lata",
      "trwałe",
      "3rd party",
      "Służy do rozróżniania użytkowników.",
    ],
    [
      "Google",
      "_gid",
      "1 dzień",
      "trwałe",
      "3rd party",
      "Służy do rozróżniania użytkowników.",
    ],
    [
      "Google",
      "_gat",
      "Do końca sesji",
      "sesyjne",
      "3rd party",
      "Służy do ograniczania szybkości żądań.",
    ],
  ],
}

export const CookiesTable: React.FC = () => {
  const styles = useStyles()

  return (
    <TableContainer className={`${styles.table}`}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            {cookiesTableData.headers.map(({ name, minWidth }) => (
              <TableCell
                key={name}
                style={{ minWidth }}
                className={styles.headTh}
              >
                {name}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {cookiesTableData.rows.map((row, id) => (
            <TableRow key={id} hover tabIndex={-1}>
              {row.map(el => (
                <TableCell key={el}>{el}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

interface ICookiesContentProps {
  url: string
}

export const CookiesContent: React.FC<ICookiesContentProps> = ({ url }) => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <h2>POLITYKA COOKIES</h2>
      <p>
        Poniższa Polityka Cookies określa zasady zapisywania i&nbsp;uzyskiwania
        dostępu do danych na Urządzeniach Użytkowników korzystających
        z&nbsp;Serwisu do celów świadczenia usług drogą elektroniczną przez
        Administratora Serwisu.
      </p>
      <h3>§ 1 DEFINICJE</h3>
      <p>
        • <span>Serwis</span> - serwis internetowy działający pod adresem{" "}
        <a target="_blank" href={url}>
          {url}
        </a>
        <br />
        <br />• <span>Serwis zewnętrzny</span> - serwis internetowy partnerów,
        usługodawców lub usługobiorców Administratora
        <br />
        <br />• <span>Administrator</span> - firma Polenergia Dystrybucja
        Sp.&nbsp;z&nbsp;o.&nbsp;o., prowadząca działalność pod adresem: ul.
        Krucza 24/26 00-526 Warszawa, świadcząca usługi drogą elektroniczną za
        pośrednictwem Serwisu oraz przechowująca i&nbsp;uzyskująca dostęp do
        informacji w&nbsp;urządzeniach Użytkownika
        <br />
        <br />• <span>Użytkownik</span> - osoba fizyczna, dla której
        Administrator świadczy usługi drogą elektroniczną za pośrednictwem
        Serwisu.
        <br />
        <br />• <span>Urządzenie</span> - elektroniczne urządzenie wraz
        z&nbsp;oprogramowaniem, za pośrednictwem, którego Użytkownik uzyskuje
        dostęp do Serwisu
        <br />
        <br />• <span>Cookies (ciasteczka)</span> - dane tekstowe gromadzone
        w&nbsp;formie plików zamieszczanych na Urządzeniu Użytkownika
      </p>
      <h3>§ 2 RODZAJE COOKIES</h3>
      <p>
        • <span>Cookies wewnętrzne</span> - pliki zamieszczane
        i&nbsp;odczytywane z&nbsp;Urządzenia Użytkownika przez system
        teleinformatyczny Serwisu
        <br />
        <br />• <span>Cookies zewnętrzne</span> - pliki zamieszczane
        i&nbsp;odczytywane z&nbsp;Urządzenia Użytkownika przez systemy
        teleinformatyczne Serwisów zewnętrznych
        <br />
        <br />• <span>Cookies sesyjne</span> - pliki zamieszczane
        i&nbsp;odczytywane z&nbsp;Urządzenia Użytkownika przez Serwis lub
        Serwisy zewnętrzne podczas jednej sesji danego Urządzenia. Po
        zakończeniu sesji pliki są usuwane z&nbsp;Urządzenia Użytkownika.
        <br />
        <br />• <span>Cookies trwałe</span> - pliki zamieszczane
        i&nbsp;odczytywane z&nbsp;Urządzenia Użytkownika przez Serwis lub
        Serwisy zewnętrzne do momentu ich ręcznego usunięcia. Pliki nie są
        usuwane automatycznie po zakończeniu sesji Urządzenia chyba że
        konfiguracja Urządzenia Użytkownika jest ustawiona na tryb usuwanie
        plików Cookie po zakończeniu sesji Urządzenia.
      </p>
      <h3>§ 3 BEZPIECZEŃSTWO</h3>
      <p>
        • <span>Mechanizmy składowania i&nbsp;odczytu</span> - Mechanizmy
        składowania i&nbsp;odczytu Cookies nie pozwalają na pobieranie
        jakichkolwiek danych osobowych ani żadnych informacji poufnych
        z&nbsp;Urządzenia Użytkownika (poza numerem IP, datą i&nbsp;godziną
        odwiedzin oraz informacjami na temat Urządzenia, takimi jak rodzaj
        systemu operacyjnego wykorzystywanego na urządzeniu oraz wersja
        i&nbsp;język przeglądarki). Przeniesienie na Urządzenie Użytkownika
        wirusów, koni trojańskich oraz innych robaków jest praktycznie
        niemożliwe.
        <br />
        <br />• <span>Cookie zewnętrzne</span> - za bezpieczeństwo plików Cookie
        pochodzących od partnerów Serwisu Administrator nie ponosi
        odpowiedzialności. Lista partnerów zamieszczona jest w&nbsp;dalszej
        części Polityki Cookie.
        <br />
        <br />• <span>Cookie zewnętrzne</span> - za bezpieczeństwo plików Cookie
        pochodzących od partnerów Serwisu Administrator nie ponosi
        odpowiedzialności. Lista partnerów zamieszczona jest w&nbsp;dalszej
        części Polityki Cookie.
      </p>
      <h3>§ 4 CELE DO KTÓRYCH WYKORZYSTYWANE SĄ PLIKI COOKIE</h3>
      <p>
        • <span>Usprawnienie i&nbsp;ułatwienie dostępu do Serwisu</span> -
        Administrator może przechowywać w&nbsp;plikach Cookie informacje
        o&nbsp;preferencjach i&nbsp;ustawieniach użytkownika dotyczących Serwisu
        aby usprawnić, polepszyć i&nbsp;przyśpieszyć świadczenie usług
        w&nbsp;ramach Serwisu.
        <br />
        <br />• <span>Dane statystyczne</span> - Administrator oraz Serwisy
        zewnętrzne wykorzystuje pliki Cookie do zbierania i&nbsp;przetwarzania
        danych statystycznych takich jak np. statystyki odwiedzin, statystyki
        Urządzeń Użytkowników czy statystyki zachowań użytkowników. Dane te
        zbierane są w&nbsp;celu analizy i&nbsp;ulepszania Serwisu.
        <br />
        <br />• <span>Ochrona przed spamem</span> - Te usługi analizują ruch
        Serwisu, potencjalnie zawierający Dane Osobowe Użytkowników, w&nbsp;celu
        filtrowania go z&nbsp;tej części ruchu, wiadomości i&nbsp;treści, które
        zostały uznane za spam.
      </p>
      <h3>§ 5 SERWISY ZEWNĘTRZNE</h3>
      <p>
        Administrator współpracuje z&nbsp;następującymi serwisami zewnętrznymi,
        które mogą zamieszczać pliki Cookie na Urządzeniach Użytkownika:
        <br />
        <br />
        <br />• <span>Google Analytics</span> - To usługa analizy sieci
        internetowej firmy Google Inc. ("Google"). Google wykorzystuje dane
        zebrane w&nbsp;celu śledzenia i&nbsp;zbadania działań dokonywanych
        w&nbsp;Serwisie, do przygotowania sprawozdań dokonywanych aktywności
        i&nbsp;wykorzystywania ich do celów świadczenia innych usług Google.
        Firma Google może wykorzystywać zebrane Dane do kontekstualizacji
        i&nbsp;personalizacji ogłoszeń z&nbsp;własnej sieci reklamowej.
        <br />
        <br />
        Dostawca: Google -{" "}
        <a target="_blank" href="https://policies.google.com/privacy?hl=en">
          Polityka Prywatności
        </a>
        <br />
        <br />
        Cel: Analityka
        <br />
        <br />
        Dane osobowe: Cookie, indywidualny identyfikator użytkownika, Dane
        dotyczące korzystania z&nbsp;Serwisu, informacje o&nbsp;tym w&nbsp;jaki
        sposób użytkownik trafił na stronę Serwisu
        <br />
        <br />
        <br />• <span>Google reCAPTCHA</span> - Recaptcha to powszechnie
        stosowana przez administratorów metoda zabezpieczania stron
        internetowych przed spamem wysyłanym przez boty, które np. automatycznie
        rejestrują konta w&nbsp;portalach czy publikują masowo komentarze na
        blogach i&nbsp;forach. Narzędzie recaptcha stosowane jest
        w&nbsp;formularzach rejestracyjnych i&nbsp;kontaktowych w&nbsp;celu
        weryfikacji użytkownika i&nbsp;potwierdzenia, że jest on człowiekiem,
        a&nbsp;nie np. spamerskim robotem.
        <br />
        <br />
        Dostawca: Google -{" "}
        <a target="_blank" href="https://policies.google.com/privacy?hl=en">
          Polityka Prywatności
        </a>
        <br />
        <br />
        Cel: Ochrona przed spamem
      </p>
      <CookiesTable />
      <h3>
        § 6 MOŻLIWOŚCI OKREŚLANIA WARUNKÓW PRZECHOWYWANIA I&nbsp;UZYSKIWANIA
        DOSTĘPU NA URZĄDZENIACH UŻYTKOWNIKA PRZEZ SERWIS I&nbsp;SERWISY
        ZEWNĘTRZNE
      </h3>
      <p>
        • Użytkownik może w&nbsp;dowolnym momencie, samodzielnie zmienić
        ustawienia dotyczące zapisywania, usuwania oraz dostępu do danych
        zapisanych plików Cookies, za pomocą ustawień przeglądarki, za której
        pomocą korzysta z&nbsp;Serwisu <br />
        <br />• Użytkownik może w&nbsp;dowolnym momencie usunąć wszelkie
        zapisane do tej pory pliki Cookie korzystając z&nbsp;ustawień
        przeglądarki, za której pomocą korzysta z&nbsp;Serwisu, albo narzędzi
        Urządzenia Użytkownika, za pośrednictwem którego Użytkownik korzysta
        z&nbsp;usług Serwisu.
      </p>
      <h3>§ 7 WYMAGANIA SERWISU</h3>
      <p>
        • Ograniczenie zapisu i&nbsp;dostępu do plików Cookie na Urządzeniu
        Użytkownika może spowodować nieprawidłowe działanie niektórych funkcji
        Serwisu.
        <br />
        <br />• Administrator nie ponosi żadnej odpowiedzialności za
        nieprawidłowo działające funkcje Serwisu w&nbsp;przypadku gdy Użytkownik
        ograniczy w&nbsp;jakikolwiek sposób możliwość zapisywania i&nbsp;odczytu
        plików Cookie.
      </p>
      <h3>§ 8 ZMIANY W&nbsp;POLITYCE COOKIE</h3>
      <p>
        • Administrator zastrzega sobie prawo do dowolnej zmiany niniejszej
        Polityki Cookie bez konieczności informowania o&nbsp;tym użytkowników.
        <br />
        <br />• Wprowadzone zmiany w&nbsp;Polityce Cookie zawsze będą
        publikowane na tej stronie.
        <br />
        <br />• Wprowadzone zmiany wchodzą w&nbsp;życie w&nbsp;dniu publikacji
        Polityki Cookie.
      </p>
    </div>
  )
}
