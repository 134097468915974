import theme from "./theme"

export const primaryColor = "#00BDA8"
export const lightColor = "#fafafa"
export const darkPrimaryColor = "#06342F"
export const greyColor = "#656D76"
export const errorRedColor = "#CC1450"

export const minLandingHeight = "800px"

export const contentPadding = "0 100px"
export const contentPaddingLg = "0 25px"
export const contentPaddingMd = "0 20px"
export const contentPaddingSm = "0 15px"

export const relativeContentPadding = () => ({
  [theme.breakpoints.down("lg")]: {
    padding: contentPaddingLg,
  },
  [theme.breakpoints.down("md")]: {
    padding: contentPaddingMd,
  },
  [theme.breakpoints.down("sm")]: {
    padding: contentPaddingSm,
  },
  padding: contentPadding,
})

export const maxWidth = {
  maxWidth: "1600px",
  margin: "auto",
}
