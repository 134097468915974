import React, { useEffect, useState } from "react"
import { useImmer } from "use-immer"

const defaultContextValue = {
  showForm: false,
  setShowForm: null,
}

const { Provider, Consumer } = React.createContext(defaultContextValue)

const ContextProviderComponent: React.FC = ({ children }) => {
  const [state, setContext] = useImmer(defaultContextValue)

  const setState = state => {
    setContext(draft => {
      draft.showForm = state
    })
  }

  useEffect(() => {
    setContext(draft => {
      draft.setShowForm = setState
    })
  }, [])

  return <Provider value={state}>{children}</Provider>
}

export { Consumer as default, ContextProviderComponent }
