// If you don't want to use TypeScript you can delete this file!
import React from "react"
import { graphql } from "gatsby"

import { Logos } from "./images/logos"
import { Logo } from "./images/logo"
import { Button, withTheme, withStyles, Theme } from "@material-ui/core"

export const StyledButton = withStyles((theme: Theme) => ({
  root: {
    textTransform: "unset",
    borderRadius: "24px",
    boxShadow:
      "-1px -2px 3px rgba(53, 160, 148, 0.15), 2px 3px 4px rgba(54, 54, 54, 0.24)",
    fontWeight: theme.typography.fontWeightBold,
    padding: "5px 25px",
    [theme.breakpoints.down("xs")]: {
      padding: "0 16px",
    },
  },
  containedPrimary: {
    border: "2.5px solid #FFFFFF",
    color: "white",
  },
  containedSecondary: {
    border: `2.5px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: theme.palette.grey[200],
    },
  },
  outlined: {
    border: `2.5px solid #555`,
    color: "#555",
    backgroundColor: "white",
    "&:hover": {
      color: "black",
      borderColor: "black",
    },
  },
}))(withTheme(Button))

export const CardStyledButton = withStyles((theme: Theme) => ({
  root: {
    textTransform: "unset",
    boxShadow:
      "-1px -2px 3px rgba(53, 160, 148, 0.15), 2px 3px 4px rgba(54, 54, 54, 0.24)",
    fontWeight: theme.typography.fontWeightBold,
    padding: "5px 25px",
    [theme.breakpoints.down("xs")]: {
      padding: "0 16px",
    },
    color: "#f2f2f2",
    backgroundColor: "#12beab",
    width: "100%",
    borderRadius: "0px",
    paddingTop: "13px",
    paddingBottom: "13px",
  },
  containedPrimary: {
    border: "0px",
    color: "white",
  },
  containedSecondary: {
    border: `2.5px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: theme.palette.grey[200],
    },
  },
  outlined: {
    border: `2.5px solid #555`,
    color: "#555",
    backgroundColor: "white",
    "&:hover": {
      color: "black",
      borderColor: "black",
    },
  },
}))(withTheme(Button))

export default StyledButton

export const FilledButton = withStyles((theme: Theme) => ({
  root: {
    width: "71px",
    color: "white",
    background: theme.palette.primary.main,
    border: "none",
    outline: "none",
    fontWeight: theme.typography.fontWeightBold,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}))(withTheme(Button))
