import React from "react"
import CssBaseline from "@material-ui/core/CssBaseline"
import { ThemeProvider } from "@material-ui/core/styles"
import { theme } from "../../src/styles/theme"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"
import { Cookies } from "./Cookies"
import { ContextProviderComponent } from "../../src/context/Context"

export const TopLayout: React.FC = ({ children }) => (
  <React.Fragment>
    <ThemeProvider theme={theme}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      <Cookies />
      <ContextProviderComponent>{children}</ContextProviderComponent>
    </ThemeProvider>
  </React.Fragment>
)
