import React, { useState } from "react"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  useTheme,
  Switch,
  Card,
} from "@material-ui/core"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles"
import { toggleGA } from "../../src/utils/analytics"
import StyledButton from "../../src/components/Button"
import { greyColor } from "../../src/styles/constants"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogTitle: {
      maxHeight: 0,
      padding: 0,
      textAlign: "right",
      "& button": {
        top: "-20px",
        right: "-20px",
        position: "relative",
        padding: "4px",
        [theme.breakpoints.up("md")]: {
          right: "-25px",
        },
      },
    },
    contentContainer: {
      display: "flex",
      flexDirection: "column",
      padding: 0,
      "& h2, h3, h4": {
        margin: 0,
      },
      "& h1": {
        margin: "0 24px 20px",
        fontSize: "28px",
        color: "#5B5B5B",
        fontWeight: theme.typography.fontWeightBold,
        textAlign: "center",
        [theme.breakpoints.down("sm")]: {
          fontSize: "20px",
        },
      },
      [theme.breakpoints.down("sm")]: {
        padding: "0 12px",
      },
    },
    cookiesCard: {
      boxShadow:
        "0 7px 9px 3px rgba(105,105,105,0.02), 0 2px 5px 0 rgba(66,65,65,0.05)",
      borderRadius: "4px",
      margin: "0 0 20px",
      padding: "24px 24px 32px",
      [theme.breakpoints.down("sm")]: {
        padding: "24px 8px 32px",
      },
    },
    cookiesTitle: {
      display: "flex",
      justifyContent: "space-between",
      margin: "0 0 16px",
      alignItems: "baseline",
      "& h3": {
        color: "#5B5B5B",
        fontSize: "20px",
        letterSpacing: "0.7px",
        fontWeight: theme.typography.fontWeightRegular,
      },
      "& span": {
        color: theme.palette.primary.main,
        paddingLeft: "4px",
        minWidth: "112px",
        textAlign: "right",
        fontSize: "12px",
        letterSpacing: "0.64px",
        fontWeight: theme.typography.fontWeightBold,
      },
      [theme.breakpoints.down("sm")]: {
        "& h3": {
          fontSize: "16px",
        },
      },
    },
    cookieContainer: {
      display: "flex",
      flexDirection: "column",
      "& p": {
        margin: 0,
        fontSize: "12px",
        color: greyColor,
        maxWidth: "240px",
        [theme.breakpoints.down("xs")]: {
          maxWidth: "74%",
        },
      },
    },
    switchContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      "& h4": {
        margin: 0,
        color: "#4E4F4F",
        fontSize: "16px",
        lineHeight: "20px",
        letterSpacing: "0.64px",
        fontWeight: theme.typography.fontWeightBold,
      },
      [theme.breakpoints.down("sm")]: {
        "& h4": {
          fontSize: "12px",
        },
      },
    },
    privacy: {
      margin: "0 auto 32px",
      width: "100%",
      color: "black",
      textAlign: "center",
      fontSize: "12px",
      letterSpacing: "0.64px",
      fontWeight: theme.typography.fontWeightBold,
      "& span": {
        margin: "0 0 16px",
        color: "#4E4F4F",
        lineHeight: "20px",
      },
      "& a": {
        margin: 0,
        cursor: "pointer",
        color: theme.palette.primary.main,
        lineHeight: "16px",
        textDecoration: "none",
        transition: "color 0.15s ease-in-out",
        "&:hover": {
          color: theme.palette.primary.dark,
        },
      },
    },
    buttonsContainer: {
      display: "flex",
      margin: "0 0 8px",
      justifyContent: "space-evenly",
      [theme.breakpoints.down("xs")]: {
        justifyContent: "space-around",
        margin: 0,
      },
      "& button": {
        height: "48px",
        width: "176px",
        fontSize: "16px",
        [theme.breakpoints.down("xs")]: {
          height: "40px",
          fontSize: "14px",
          width: "40%",
          margin: "0 0 20px",
        },
      },
    },
  })
)

interface Props {
  showSettings: boolean
  toggleShowSettings: (val: boolean) => void
  googleAnalytics: boolean
  setGoogleAnalytics: (val: boolean) => void
  markCookies: () => void
  showCookiesPolicy: (val: boolean) => void
}

export const CookiesSettings: React.FC<Props> = ({
  showSettings,
  toggleShowSettings,
  googleAnalytics,
  setGoogleAnalytics,
  markCookies,
  showCookiesPolicy,
}) => {
  const styles = useStyles()

  return (
    <Dialog
      aria-describedby="optional-cookies"
      open={showSettings}
      onClose={() => toggleShowSettings(false)}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle className={styles.dialogTitle}>
        <IconButton
          aria-label="close"
          onClick={() => toggleShowSettings(false)}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={styles.contentContainer}>
        <h1>Ustawienia cookies</h1>

        <div className={styles.cookiesCard}>
          <div className={styles.cookiesTitle}>
            <h3>Wymagane pliki cookies</h3>
            <span>OBOWIĄZKOWE</span>
          </div>
          <label className={styles.cookieContainer}>
            <div className={styles.switchContainer}>
              <h4>Google reCaptcha</h4>
              <Switch checked={true} disabled />
            </div>
            <p>Cel: Ochrona przed spamem</p>
          </label>
        </div>

        <div className={styles.cookiesCard}>
          <div className={styles.cookiesTitle}>
            <h3>Opcjonalne pliki cookies</h3>
            <span>{googleAnalytics ? "WŁĄCZONE" : "WYŁĄCZONE"}</span>
          </div>
          <label className={styles.cookieContainer}>
            <div className={styles.switchContainer}>
              <h4>Google Analytics</h4>
              <Switch
                checked={googleAnalytics}
                onChange={e => setGoogleAnalytics(e.target.checked)}
              />
            </div>
            <p>
              Cel: Analiza zachowania użytkowników w&nbsp;celu poprawy
              atrakcyjności serwisu
            </p>
          </label>
        </div>

        <p className={styles.privacy}>
          <span>Potrzebujesz dokładniejszej informacji?</span> <br />
          <a onClick={() => showCookiesPolicy(true)}>
            Zapoznaj się z&nbsp;polityką dotyczącą plików cookies
          </a>
        </p>

        <div className={styles.buttonsContainer}>
          <StyledButton
            color="secondary"
            variant="contained"
            onClick={() => {
              toggleShowSettings(false)
            }}
          >
            Anuluj
          </StyledButton>
          <StyledButton
            color="primary"
            variant="contained"
            onClick={() => {
              toggleGA(googleAnalytics)
              markCookies()
              toggleShowSettings(false)
            }}
          >
            Zapisz
          </StyledButton>
        </div>
      </DialogContent>
    </Dialog>
  )
}
