import React, { useState, useEffect } from "react"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Button,
  useTheme,
} from "@material-ui/core"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles"
import { animated, Transition } from "react-spring"
import { relativeContentPadding, maxWidth } from "../../src/styles/constants"
import { CookiesContent } from "./CookiesContent"
import {
  toggleGA,
  gaCookieName,
  getCookieValue,
} from "../../src/utils/analytics"
import { CookiesSettings } from "./CookiesSettings"
import { appDescriptions } from "../../appDescriptions"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cookiesContainer: {
      ...relativeContentPadding(),
      position: "fixed",
      left: 0,
      right: 0,
      zIndex: 100,
      background: "#2a2a2a",
    },
    cookiesContent: {
      ...maxWidth,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      color: "white",
      height: "92px",
      [theme.breakpoints.down("md")]: {
        "& h5": {
          fontSize: "20px",
        },
      },
      [theme.breakpoints.down("sm")]: {
        height: "108px",
        flexWrap: "wrap",
        justifyContent: "center",
        "& h5": {
          fontSize: "18px",
          textAlign: "center",
        },
      },
      [theme.breakpoints.down("xs")]: {
        height: "136px",
        "& h5": {
          fontSize: "16px",
        },
      },
    },
    dialogTitle: {
      maxHeight: 0,
      padding: 0,
      textAlign: "right",
      "& button": {
        top: "-20px",
        right: "-20px",
        position: "relative",
        padding: "4px",
        [theme.breakpoints.up("md")]: {
          top: "-25px",
          right: "-30px",
        },
      },
    },
    buttonsContainer: {
      display: "flex",
      minWidth: "420px",
      [theme.breakpoints.down("sm")]: {
        justifyContent: "center",
        maxWidth: "100%",
      },
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
        alignItems: "center",
        marginBottom: "4px",
      },
    },
    btn: {
      minWidth: "170px",
      padding: "0 12px",
      fontSize: "20px",
      textTransform: "none",
      [theme.breakpoints.down("sm")]: {
        minWidth: "206px",
        fontSize: "16px",
      },
      [theme.breakpoints.down("xs")]: {
        minWidth: "170px",
        fontSize: "14px",
      },
    },
    acceptButton: {
      width: "100%",
      border: "2px solid #FFFFFF",
      borderRadius: "24px",
      padding: "12px 12.4px 12px 12.4px",
      fontSize: "14px",
      color: "white",
      letterSpacing: "0.56px",
      fontWeight: theme.typography.fontWeightBold,
      transition: "background-color 0.2s ease-in-out",
      [theme.breakpoints.down("xs")]: {
        marginTop: "4px",
        width: "196px",
      },
      "&:hover": {
        backgroundColor: theme.palette.secondary.dark,
      },
    },
    link: {
      color: theme.palette.primary.main,
      cursor: "pointer",
      textDecoration: "none",
    },
  })
)

const COOKIE_KEY = "cookieAccepted"

export const Cookies: React.FC = () => {
  const styles = useStyles()
  const theme = useTheme<Theme>()

  const [visible, setVisible] = useState(false)
  const [showCookiesPolicy, setShowCookiesPolicy] = useState(false)
  const [showCookiesOptions, setShowCookiesOptions] = useState(false)
  const [googleAnalytics, setGoogleAnalytics] = useState(false)

  const markCookiesAsAccepted = () => {
    localStorage.setItem(COOKIE_KEY, "true")
    setVisible(false)
  }

  useEffect(() => {
    if (
      typeof window !== "undefined" &&
      localStorage.getItem(COOKIE_KEY) === "true" &&
      getCookieValue(gaCookieName) === ""
    ) {
      setVisible(false)
      setGoogleAnalytics(true)
      toggleGA(true)
    } else {
      setVisible(
        typeof window === "undefined" || !localStorage.getItem(COOKIE_KEY)
      )
      const ga = getCookieValue(gaCookieName) === "true"
      setGoogleAnalytics(ga)
      toggleGA(ga)
    }
  }, [])

  return (
    <>
      <Transition
        items={visible}
        from={{ opacity: 0 }}
        enter={{ opacity: 1 }}
        leave={{ opacity: 0 }}
      >
        {(values, item) =>
          item && (
            <animated.div style={values} className={styles.cookiesContainer}>
              <div className={styles.cookiesContent}>
                <Typography variant="h5">
                  Strona korzysta z plików cookies - zapoznaj się z{" "}
                  <a
                    className={styles.link}
                    onClick={() => setShowCookiesPolicy(true)}
                  >
                    polityką dotyczącą plików cookies.
                  </a>
                </Typography>

                <div className={styles.buttonsContainer}>
                  <Button
                    size="large"
                    color="primary"
                    className={styles.btn}
                    onClick={() => setShowCookiesOptions(true)}
                  >
                    Więcej opcji
                  </Button>

                  <Button
                    size="large"
                    color="primary"
                    className={`${styles.btn} ${styles.acceptButton}`}
                    onClick={() => {
                      toggleGA(true)
                      markCookiesAsAccepted()
                    }}
                  >
                    Akceptuj wszystkie
                  </Button>
                </div>

                <Dialog
                  aria-describedby="cookies-content"
                  open={showCookiesPolicy}
                  onClose={() => {
                    setShowCookiesPolicy(false)
                  }}
                  maxWidth="md"
                  fullWidth
                >
                  <DialogTitle className={styles.dialogTitle}>
                    <IconButton
                      aria-label="close"
                      onClick={() => {
                        setShowCookiesPolicy(false)
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </DialogTitle>
                  <DialogContent>
                    <CookiesContent url={appDescriptions.url} />
                  </DialogContent>
                </Dialog>

                <CookiesSettings
                  showSettings={showCookiesOptions}
                  toggleShowSettings={setShowCookiesOptions}
                  googleAnalytics={googleAnalytics}
                  setGoogleAnalytics={setGoogleAnalytics}
                  markCookies={markCookiesAsAccepted}
                  showCookiesPolicy={setShowCookiesPolicy}
                />
              </div>
            </animated.div>
          )
        }
      </Transition>
    </>
  )
}
