import { createMuiTheme, darken } from "@material-ui/core/styles"
import createBreakpoints from "@material-ui/core/styles/createBreakpoints"

import {
  primaryColor,
  darkPrimaryColor,
  lightColor,
  errorRedColor,
} from "./constants"

const breakpoints = createBreakpoints({})

export const theme = createMuiTheme({
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "h1, h2, h3, h4, h5": {
          margin: "10px 0",
        },
      },
    },
    MuiButton: {
      root: {
        minWidth: "42px",
      },
    },
    MuiPaper: {
      root: {
        padding: "30px 40px",
        backgroundColor: "#FFFFFF",
        position: "relative",
        [breakpoints.down("sm")]: {
          padding: "20px",
          width: "calc(100% - 20px) !important",
          maxWidth: "calc(100% - 20px) !important",
        },
      },
      elevation1: {
        boxShadow:
          "0px 7px 9px 7px rgba(105, 105, 105, 0.01), 0px 5px 6px rgba(74, 73, 73, 0.05), 0px 7px 15px 2px rgba(66, 65, 65, 0.03)",
      },
      rounded: {
        borderRadius: "8px",
      },
    },
    MuiDialog: {
      paper: {
        margin: "20px 0",
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: "rgba(190, 205, 202, 0.93)",
      },
    },
  },
  typography: {
    fontFamily: `"Muli", "Helvetica", "Arial", sans-serif`,
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
  palette: {
    primary: {
      main: primaryColor,
      light: lightColor,
      dark: darkPrimaryColor,
    },
    secondary: {
      main: darken(primaryColor, 0.1),
      light: darken(lightColor, 0.2),
    },
    error: {
      main: errorRedColor,
    },
  },
})

export default theme
