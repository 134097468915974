interface IEvent {
  category: string
  label?: string
  action?: string
}

export const gaCookieName = "gdpr-analytics-enabled"
const maxRetries = 5

const retries = {}

const eventsQueue = []

export function getCookieValue(a) {
  var b = document.cookie.match("(^|;)\\s*" + a + "\\s*=\\s*([^;]+)")
  return b ? b.pop() : ""
}

export const trackCustomEvent = ({ category, action, label }: IEvent) => {
  const cookieValue = getCookieValue(gaCookieName)
  if (!cookieValue || getCookieValue(gaCookieName) === "false") {
    eventsQueue.push({ category, action, label })
    return
  }

  const uniqueId = `${category}${action}${label}`
  const retriesCount = retries[uniqueId]
  if (retriesCount > maxRetries) return

  // handle waiting for analytics script to be loaded
  if (!(window as any).gaLoaded) {
    retries[uniqueId] = retriesCount ? retriesCount + 1 : 1
    setTimeout(() => {
      trackCustomEvent({ category, action, label })
    }, 200)
    return
  }

  if (!(window as any).trackGoogleAnalyticsEvent) return
  ;(window as any).trackGoogleAnalyticsEvent(action, {
    event_category: category,
    event_label: label,
  })
}

function sendAllEventsFromQueue() {
  let event = eventsQueue.shift()

  if (!event) return
  trackCustomEvent(event)
  sendAllEventsFromQueue()
}

export const toggleGA = (ga: boolean) => {
  const value = ga ? "=true; " : "=false; "
  document.cookie =
    gaCookieName + value + "expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/"

  if ((window as any).trackGoogleAnalytics) {
    ;(window as any).trackGoogleAnalytics()
  }

  if (ga) {
    sendAllEventsFromQueue()
  }
}
