const getAppDescriptions = appName => {
  switch (appName) {
    case "newOfferLanding":
      return {
        title: "Polenergia | Nowa oferta",
        description:
          "Nowa oferta z Polenergią Dystrybucja. Wybierz ofertę energii elektrycznej dopasowaną do swoich potrzeb.",
        author: "Polenergia",
        name: "Polenergia | Nowa oferta",
        shortName: "Nowa oferta",
        url: "https://nowaoferta.polenergia.pl/",
      }
    case "lightsOnLanding":
      return {
        title: "Polenergia | Włącz prąd",
        description:
          "Włącz prąd z Polenergią Dystrybucja. Wybierz ofertę energii elektrycznej dopasowaną do swoich potrzeb.",
        author: "Polenergia",
        name: "Polenergia | Włącz prąd",
        shortName: "Włącz prąd",
        url: "https://wlaczprad.polenergia.pl/",
      }
    default:
      return {
        title: "Polenergia | Nowa oferta",
        description:
          "Nowa oferta z Polenergią Dystrybucja. Wybierz ofertę energii elektrycznej dopasowaną do swoich potrzeb.",
        author: "Polenergia",
        name: "Polenergia | Nowa oferta",
        shortName: "Nowa oferta",
        url: "https://nowaoferta.polenergia.pl/",
      }
  }
}

export const appDescriptions = getAppDescriptions(
  process.env.GATSBY_APP || "landing"
)
